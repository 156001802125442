// ADD CSS TO THIS PAGE
import "../styles/404.css";

export const NOTFOUND = (props: any) => {
  return (
    <div id="main">
      <div className="fof">
        <h1>Error 404</h1>
      </div>
    </div>
  );
};
